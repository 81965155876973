import React from "react";
import dataNav from "../../data/Navbar/navbar-data.json";
import useWindowResizeListener from "../../helpers/useWindowResizeListener";
import MegaDropdown from "./MegaDropdown";
import DropdownItem from "./DropdownItem";
import { Link } from "react-router-dom";
const DropdownMenu = () => {
  useWindowResizeListener();

  return (
    <div className="collapse navbar-collapse" id="navbar-menu">
      <ul className="nav navbar-nav" data-in="fadeIn" data-out="fadeOut">
        {dataNav.map((dropdown, i) => (
          <li
            className={
              "dropdown nav-item " +
              (dropdown.megaMenu === true ? "megamenu-fw" : "")
            }
            onClick={<Link to={dropdown.link} />}
            key={i}
          >
            <a href={dropdown.link} className="nav-link">
              {dropdown.title}
            </a>
            {dropdown.megaMenu ? (
              <MegaDropdown
                item={dropdown.subMenu}
                columnTitle={dropdown.colsTitle}
                itemsPerCol={dropdown.itemsPerCol}
              />
            ) : (
              <DropdownItem item={dropdown.subMenu} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenu;
