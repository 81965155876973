import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import PageTitleContact from "../../components/PageTitle/PageTitleContact";
import ContactThree from "../../components/ContactUs/ContactThree";
import FooterOne from "../../components/Footer/FooterOne";
import Map from "../../components/Maps/Map";

const ContactSimple = () => (
  <Loader>
    <HeaderOne />
    <PageTitleContact title="Kontakt" tagline="" />
    <ContactThree />
    <section className="pt-0 pb-0 map-section">
      <Map classAppend="wide" />
      <a
        href="https://wa.me/+4915758944258"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </section>

    <FooterOne />
  </Loader>
);

export default ContactSimple;
