import React, { useEffect, useRef, useState, forwardRef } from "react";
import dataPortfolio from "../../data/Portfolio/autofolierung.json";
import PortfolioItem from "./PortfolioItem";
import PortfolioFilter from "../../elements/Portfolio/PortfolioFilter";
import Shuffle from "shufflejs";
import "react-image-lightbox/style.css";

const Portfolio = forwardRef(
  ({ filter, layout, columns, space, items, classAppend, children }, ref) => {
    const categories = [
      "all",
      "teilfolierung",
      "scheibentönung",
      "lackschutzfolierung",
    ];
    const element = useRef();
    const [shuffle, setShuffle] = useState();

    useEffect(() => {
      if (element.current) {
        setShuffle(
          new Shuffle(element.current, {
            itemSelector: ".portfolio-item",
          })
        );
      }
    }, []);
    const filterElements = (evt) => {
      const btn = evt.currentTarget;
      evt.target.parentElement
        .querySelectorAll(".active")
        .forEach((e) => e.classList.remove("active"));
      evt.currentTarget.classList.add("active");
      const cat = btn.getAttribute("value");
      shuffle.filter((element) => {
        return element.getAttribute("data-groups").toLowerCase().includes(cat);
      });
    };

    return (
      <section
        className={"pb-0 " + (classAppend ? classAppend : "")}
        id="work"
        ref={ref}
      >
        {children ? (
          <div className="container">
            <div className="row">{children}</div>
          </div>
        ) : null}
        <div className={"container" + (layout === "wide" ? "-fluid" : "")}>
          <div className="row">
            <div
              className={
                "container" +
                (layout === "wide" ? "-fluid" : "") +
                " text-center"
              }
            >
              {filter === "true" ? (
                <PortfolioFilter
                  categories={categories}
                  handleClick={filterElements}
                />
              ) : null}

              <div id="portfolio-grid" ref={element} className="hover-two row">
                {items
                  ? dataPortfolio
                      .filter((v, i) => i < items)
                      .map((item, i) => (
                        <PortfolioItem
                          key={item.id}
                          title={item.title}
                          category={item.category}
                          image={item.image}
                          groups={item.groups}
                          link={item.link}
                          space={space ? "true" : "false"}
                          columns={columns}
                        />
                      ))
                  : dataPortfolio.map((item, i) => (
                      <PortfolioItem
                        key={item.id}
                        title={item.title}
                        category={item.category}
                        image={item.image}
                        groups={item.groups}
                        link={item.link}
                        space={space ? "true" : "false"}
                        columns={columns}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default Portfolio;
