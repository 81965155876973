import React from "react";

const TabsDefault = () => (
  <section>
    <div className="container">
      <div className="row">
        <div className="col-sm-8 section-heading">
          <h2
            style={{
              fontSize: "26px",
              marginBottom: "-10px",
              marginTop: "-15px",
            }}
          >
            So erarbeiten wir ihre Scheibentönung
          </h2>
        </div>
      </div>
      <div className="row mt-50">
        <p style={{ fontSize: "24px", color: "black" }}>
          Eine professionelle Scheibentönung sieht nicht nur sehr gut aus,
          sondern bietet auch viele weitere Vorteile. Der wohl beste Vorteil
          ist, dass die Sonne nicht mehr direkt in das Fahrzeuginnere scheinen
          kann. Bei unseren hochwertigen Folien bewirken sie einen Hitzeschutz,
          der mehr als 50 % der Wärmeentwicklung durch Sonneneinstrahlung
          verhindert – dazu kommt ein fast 100%-iger UV-Schutz, der den
          Passagieren auf den Rücksitzen Komfort und Sicherheit bietet.
        </p>
        <p style={{ fontSize: "24px", color: "black" }}>
          Zugleich bietet eine Tönung einen idealen Sichtschutz und kann dadurch
          auch Schutz vor Langfingern bieten und verschafft dem Fahrzeug einen
          exklusiven Look, der gerade auch bei den jungen Autofahrern beliebt
          ist. Wir beraten Sie gerne und wählen mit Ihnen gemeinsam die passende
          Scheibentönung individuell auf ihr Fahrzeug abgestimmt aus.
        </p>
        <p style={{ fontSize: "24px", color: "black" }}>
          Die Folie wird von außen an die jeweilige Scheube angepasst und
          zugeschnitten sowie vorgewölbt. Die Montage wird dann anschließend
          nass von innen an die Scheiben angebracht. Hierbei wird mit größter
          Sorgfalt vorgegangen und nach erfolgter Montage alles gereinigt.
        </p>
        <p style={{ fontSize: "24px", color: "black" }}>
          Selbstverständlich werden keine Blasen oder Falten zu sehen sein,
          dennoch kann es zu vereinzelten Staubeinschlüssen kommen, da die
          Umgebungsluft nicht zu 100 % rein ist.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>
          Muss eine Tönungsfolie eingetragen/zugelassen werden?
        </h2>
        <p style={{ fontSize: "24px", color: "black" }}>
          Die Scheiben tönen wir ausschließlich mit Tönungsfolie, die für den
          Straßenverkehr zugelassen ist. Auf jeder Folie, die wir an den
          Scheiben anbringen ist eine allgemeine Bauartgenehmigung (AGB)
          eingeprägt, die durch das Kraftfahrtbundesamt zugelassen ist. Wichtig
          ist die ABG für den TÜV Besuch. Aufgrund dieser ABG, ist die von uns
          verwendete Scheibentönungsfolie eintragungsfrei.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>Hersteller</h2>
        <p style={{ fontSize: "24px", color: "black" }}>
          Es gibt unzählige Varianten und Hersteller von Scheibentönungsfolien
          und gerade im Internet bekommt man oft Schnäppchenpreise angeboten.
          Wir von UNDERGROUND WRAP verwenden ausschließlich Tönungsfolie im
          Premium Segment, die wir individuell auf Ihr Fahrzeug passgenau
          zuschneiden.
        </p>
      </div>
    </div>
  </section>
);

export default TabsDefault;
