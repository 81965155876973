import React from "react";

const TabsDefault = () => (
  <section>
    <div className="container">
      <div className="row">
        <div className="col-sm-8 section-heading">
          <h3
            style={{
              fontSize: "26px",
              marginBottom: "-10px",
              marginTop: "-15px",
            }}
          >
            So erarbeiten wir ihre Lackschutzfolierung
          </h3>
        </div>
      </div>
      <div className="row mt-50">
        <p style={{ fontSize: "20px", color: "black" }}>
          Die Art der Folierung wie man sein Fahrzeug best möglichst schützt hat
          viele Namen: PPF (Paint Protection Film), Lackschutzfolierung,
          Selbstheilungsfolie, Steinschlagschutzfolierung etc. Eines haben aber
          alle gemeinsam, hier werden die gewünschten oder am stärksten zu
          schützenden Bauteile mit einer bis zu 200 ym Dicken transparenten,
          seidenmatten, oder farbigen Schutzfolie beklebt. Wir verwenden in
          diesem Bereich nur Folien welche selbstheilend, sehr Hydrophob(also
          einen hohen Abperleffekt haben) und kratzfest sind. Der
          Selbstheilungseffekt, welcher leichte Kratzer und Beschädigungen
          repariert, tritt ab 25 °C auf oder kann durch heißes, nicht kochendes
          Wasser aktiviert werden.
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Ein toller Nebeneffekt von glänzenden Folien sind ein wahnsinniger
          WET-Look, der Ihr Fahrzeug in ein noch glänzenderes Licht rückt. Durch
          die Verklebung solch einer Schutzfolie schützen Sie den Lack Ihres
          Fahrzeuges entweder von Beginn an oder nach einer umfassenden
          Lackanalyse sowie Aufbereitung.
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Die wichtigsten Gründe solch einer Folierung sind der nachhaltige
          Schutz vor Kratzern, Vandalismus, Steinschlägen und vielen weiteren
          Umwelteinflüssen, wie z. B. auch dem Salz in den Wintermonaten.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>Kosten und Zeitaufwand</h2>
        <p style={{ fontSize: "20px", color: "black" }}>
          Die Kosten für eine PPF richten sich nach den zu folierenden
          Bauteilen, sowie Aufwändigkeit und Folie ab. Je mehr Winkel,
          Luftein-/Auslässe oder Kanten das Bauteil aufweist, desto höher können
          die Kosten werden.
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Auch sind die Kosten abhängig von der jeweiligen Foliertechnik. Hier
          wird in 2 Bereiche unterteilt: Custommade oder mit Schablonen.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>Custommade oder Schablone?</h2>
        <p style={{ fontSize: "20px", color: "black" }}>
          Welche Technik wir bei der Steinschlagschutz Folierung Ihres Fahrzeugs
          anwenden sollen, hängt von Ihren Wünschen, Ihrem Budget und Ihrem
          Anspruch an die Fahrzeugoptik ab. Bei der Schablonentechnik verwenden
          wir Schablonen, die auf dem jeweiligen Fahrzeugbauteil platziert
          werden. Diese vorgefertigten Schablonen enden jedoch knapp fünf
          Millimeter vor dem jeweiligen Bauteil und hinterlassen somit eine
          Sichtkante. Unsere Variante Custommade unterscheidet sich zur weit
          verbreiteten Schablonentechnik vor allem darin, dass wir alle
          Fahrzeugbauteile bestmöglich bis in die letzte Ecke und über nahezu
          jede Kante mit unserer Premium Lackschutzfolie schützen. An den
          Stellen, an denen die „passgenauen“ Schablonen aufhören, verlängern
          wir diese ganz individuell und haben so die Möglichkeit, das gesamte
          Bauteil zu schützen. Im Gegensatz zu einem herkömmlichen
          Steinschlagschutz Schablonen Montage, welche deutlich einfacher und
          schneller umzusetzen ist, ist die Custommade-Variante anspruchsvoller
          und vor allem optisch um einiges ansprechender. Bei der Custommade
          Lackschutz-Technik garantieren wir Ihnen, dass Ihr Fahrzeug an allen
          Stellen voll umfassend geschützt ist. Ausgenommen davon sind lediglich
          Bauteile, welche zu enge Spaltmaße aufweisen. Hier würde sich die
          Folie an den entsprechenden Bauteilen gegeneinander aufschiebt.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>
          Schutzbereich/Teilfolierung Steinschlagschutz:
        </h2>
        <p style={{ fontSize: "20px", color: "black" }}>
          Die häufigste Variante ist hier das Frontpaket. Es enthält:
          Frontschürze, Kotflügel, Motorhaube und Außenspiegel. Nach einer
          Steinschlagschutz Folierung sind diese Bauteile dann optimal vor
          Steinschlag und Kratzern geschützt.
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Zur Vermeidung von Schmutzkanten und Parkremplern an Bauteilen, wie
          zum Beispiel den Seitenwänden, empfehlen wir eine
          Lackschutz-Vollfolierung. Der Originallack von Ihrem gesamten Fahrzeug
          wird optimal und nachhaltig geschützt. Selbstverständlich können aber
          auch kleinere Bereiche, wie die Ladekante oder Einstiegsleisten, mit
          Steinschlagschutz foliert werden und somit vor lästigen Beschädigungen
          geschützt werden.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>
          Was tun bei einer Beschädigung der Folie?
        </h2>
        <p style={{ fontSize: "20px", color: "black" }}>
          Bei einer Beschädigung der Steinschlagschutzfolie ist der notwendige
          Aufwand einer Reparatur deutlich geringer als bei einer
          Fahrzeuglackierung. Die beschädigte Lackschutzfolie kann an dem
          jeweiligen Bauteil rückstandslos entfernt und ausgetauscht werden.
        </p>
        <p style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}>
          Nachdem Sie die Folierung Ihrem Kasko Versicherer gemeldet haben, ist
          die Folie mit versichert!
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Sollten Sie das Fahrzeugbauteil vorher lackieren lassen, bevor eine
          Folierung auf einer nachlackierten Stelle durchgeführt wird, empfiehlt
          es sich, eine Aushärtungszeit des Lacks von 4 bis 6 Wochen je nach
          Jahreszeit einzuhalten.
        </p>
        <br></br>
        <h2 style={{ fontSize: "26px" }}>Haltbarkeit und Lebensdauer</h2>
        <p style={{ fontSize: "20px", color: "black" }}>
          Die Hersteller versprechen, je nach Pflege des Fahrzeuges, eine
          Garantie auf die Folie von bis zu 10 Jahren sowie auf rückstandslose
          Entfernung und Vergilbung. Wir geben euch eine 2-jährige Garantie auf
          unsere Arbeit.
        </p>
      </div>
    </div>
  </section>
);

export default TabsDefault;
