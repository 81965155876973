import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import HeaderOne from "../../components/Header/HeaderOne";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import WelcomeTwo from "../../components/WelcomeSection/WelcomeTwo";
import CTAFour from "../../components/CTA/CTAFour";
import FooterOne from "../../components/Footer/FooterOne";

const About = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const [toggler, setToggler] = useState(false);
  var tagline = "Autofolierung in Ansbach und Kitzingen";
  const handleToggler = () => {
    setToggler(!toggler);
  };

  return (
    <Loader>
      <HeaderOne />
      <PageTitleAbout title="Underground Wrap" tagline={tagline} />
      <WelcomeTwo toggler={toggler} handleToggler={handleToggler} />
      <CTAFour
        title="Lass uns deinen Traum verwirklichen"
        tagline="Gemeinsam zu deinem individuellen Auto."
        textButton="Kontakt"
      >
        Als qualitativ hochwertiger Ansprechspartner, verhelfen wir dir dein
        Auto individuell zu gestalten.
      </CTAFour>
      <FooterOne />
    </Loader>
  );
};

export default About;
