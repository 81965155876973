import React from "react";
import Loader from "../../../components/Loader/Loader";
import HeaderOne from "../../../components/Header/HeaderOne";
import PageTitleWidget4 from "../../../components/PageTitle/PageTitleWidget4";
import FooterOne from "../../../components/Footer/FooterOne";
import TabsTeilfolierung from "../../../components/Tabs/TabsTeilfolierung";
import PortfolioMasonry from "../../../components/Portfolio/PortfolioMasonry";
import dataPortfolio from "../../../data/Portfolio/teilfolierung.json";
const Teilfolierung = () => (
  <Loader>
    <HeaderOne />
    <PageTitleWidget4 title="Teilfolierung" />
    <TabsTeilfolierung />
    <PortfolioMasonry
      columns="four"
      layout="wide"
      type="product"
      space={false}
      classAppend="dark-bg pt-0 pb-0"
      data={dataPortfolio}
    />
    <FooterOne />
  </Loader>
);

export default Teilfolierung;
