import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Home pages
import Home from "./pages/home/Home";

// Pages
import About from "./pages/about/About";
import Leistungen from "./pages/services/Leistungen";
import Teilfolierung from "./pages/services/pages/Teilfolierung";
import Komplettfolierung from "./pages/services/pages/Komplettfolierung";
import Lackschutzfolierung from "./pages/services/pages/Lackschutzfolierung";

import Scheibentönung from "./pages/services/pages/Scheibentönung";
import Contact from "./pages/contact/Contact";
import Page404 from "./pages/others/Page404";
import Privacy from "./pages/others/Privacy";
import Impressum from "./pages/others/Impressum";

// Elements
import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <Router basename={"/"}>
      <ScrollToTop>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/leistungen`}
            component={Leistungen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/lackschutzfolierung`}
            component={Lackschutzfolierung}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/komplettfolierung`}
            component={Komplettfolierung}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/teilfolierung`}
            component={Teilfolierung}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/scheibentoenung`}
            component={Scheibentönung}
          />
          {/*           <Route
            exact
            path={`${process.env.PUBLIC_URL}/moebelfolierung`}
            component={Möbelfolierung}
          /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/404-page`}
            component={Page404}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/datenschutz`}
            component={Privacy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Impressum`}
            component={Impressum}
          />
          <Route path="*" exact={true} component={Page404} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
