import React from "react";

const TabsDefault = () => (
  <section>
    <div className="container">
      <div className="row">
        <div className="col-sm-8 section-heading">
          <h2
            style={{
              fontSize: "26px",
              marginBottom: "-10px",
              marginTop: "-15px",
            }}
          >
            So erarbeiten wir ihre Teilfolierung
          </h2>
        </div>
      </div>
      <div className="row mt-50">
        <p style={{ fontSize: "20px", color: "black" }}>
          Bei dieser Art der Folierung sind die Möglichkeiten unerschöpflich.
          Was in der letzten Zeit häufig auch in social Media gezeigt wird, ist
          das Camouflage Design in unterschiedlichsten Formen und Farben.
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Ebenfalls eine beliebte Form der Teilfolierung ist das sogenannte
          Chrome Delete. Hier werden z. B. die Chromleisten um die Fenster, den
          Chromfarbigen Kühlergrill oder die Dachreling in Schwarz glänzend
          foliert um einen edleren Look oder das Matching zur Vollfolierung zu
          vollenden. Auch Steinschlagschutzfolie an ausgewählten Bauteilen ist
          ebenfalls eine Art der Teilfolierung. Schreiben Sie uns über das
          Kontaktformular oder per WhatsApp an, um einen unverbindlichen
          Beratungstermin auszumachen.
        </p>
        <p style={{ fontSize: "20px", color: "black" }}>
          Wir werden Ihnen ein einzigartiges Design auf ihr Auto zaubern.
        </p>
      </div>
    </div>
  </section>
);

export default TabsDefault;
