import React from "react";

import WelcomeFeatures from "./WelcomeFeatures";
import HeadingThree from "../../components/HeadingSection/HeadingThree";
import TeamSlider from "../Team/TeamSlider";

const WelcomeTwo = ({ toggler, handleToggler }) => (
  <section className="dark-bg pt-0 pb-0">
    <div className="container-fluid">
      <div className="row row-flex flex-center">
        <HeadingThree
          title="Underground Wrap"
          tagline="Autofolierung mit Herz."
        >
          <p style={{ fontSize: "20px" }}>
            Alles begann mit einem Tuningtreffen. Es faszinierte mich nicht nur
            die Standardfarben der Autos perfekt in Szene gesetzt zu sehen,
            sondern auch die ein oder andere ausgefallene Farbe, die einem aus
            dem Kopf nicht mehr rausgeht. Ich sprach mit den Leuten vor Ort und
            sie sagten, dass das Folie sei. Ich war so beeindruckt, da man das
            auf den ersten Blick, nicht erkennen konnte, dass ich mich sofort,
            als ich daheim war ans recherchieren machte.
          </p>
          <p style={{ fontSize: "20px" }}>
            Nach umfassender Recherche und den ein oder anderen Internet Videos
            packte mich der Ehrgeiz das auch mal zu versuchen. Ich bestellte
            also, aus lauter Unerfahrenheit heraus aus dem Internet eine Rolle
            Folie und machte mich an das Auto einer Freundin, die sowieso einen
            neuen Look wollte. Nach unzähligen Stunden, Misserfolgen, zusammen
            geknüllten Folien und Schweiß, zeigten sich aber doch erste
            Ergebnisse, die sich durchaus sehen lassen konnten. Das genannte
            Mädel war so begeistert, dass wir beschlossen, das ganze Fahrzeug in
            den neuen Look zu kleiden.
          </p>
          <p style={{ fontSize: "20px" }}>
            Ich glaube nach einer Woche Urlaub, in dem ich eigentlich nichts
            machen wollte, dann aber doch das Fahrzeug fertig hatte sah ich das
            Ergebnis und war dafür, dass ich das zum ersten Mal gemacht habe
            echt beeindruckt wie gut es geklappt hat. Die ersten Wochen
            vergingen und immer mehr Leute sahen meine Arbeit und wollten
            ebenfalls, dass ich Ihrem Auto eine neue Optik verpasse. Also machte
            ich mich wieder daran, im Internet zu recherchieren, wie andere das
            Auto vielleicht schon gemacht hatten, bestellte wieder Folie und
            machte mich ans Werk.
          </p>
          <p style={{ fontSize: "20px" }}>
            Irgendwann bekam ich so viele Anfragen von Leuten, die ich kannte
            oder meine Arbeit sahen, dass ich mir sagte, ich kann so nicht mehr
            weiter machen, ich will das professionell betreiben. Alles was und
            wie ich das gemacht habe, war noch nicht professionell und andere
            konnten das mit Sicherheit besser. So entschied ich mich, mir eine
            Firma zu suchen, die mir das Handwerk professionell beibringen
            würde. Ich bin fündig geworden, habe eine Woche jeden Tag 8 Stunden
            Theorie sowie Praxis gepaukt und habe meine Zertifikate für
            Fahrzeugfolierung sowie Scheibentönung erhalten.
          </p>
          <p style={{ fontSize: "20px" }}>
            Viele Leute meinten ich sollte mein Hobby zum Beruf machen, da man
            das, was ich ablieferte, wirklich perfekt war. Nun ja, die Zeit ist
            gekommen und ich habe mich entschieden ein Kleingewerbe mit dem
            Namen UNDERGROUND WRAP zu eröffnen, mit der Passion auch anderen
            Leuten, die wie ich sind, Träume zu erfüllen. Der Name übrigens ist
            durch das Konsolenspiel Need for Speed Underground entstanden.
            Damals als kleiner Kerl war ich schon begeistert von Autos und hab
            hier schon mit Designs und Farben experimentiert.
          </p>
          <p style={{ fontSize: "20px" }}>Soviel zu mir.</p>
          <p style={{ fontSize: "bold", color: "red" }}>LG Marco Frommhold</p>
        </HeadingThree>
        <div className="col-md-4 col-sm-12 team-member-wrapper">
          <div className="col-inner">
            <TeamSlider />
          </div>
        </div>
        <WelcomeFeatures title="" tagline="" />
      </div>
    </div>
  </section>
);

export default WelcomeTwo;
