import React from "react";
import dataServices from "../../data/Services/leistungen-underground-wrap.json";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

const WhoWeAreFive = () => (
  <section style={{ marginTop: "-25px" }}>
    <div className="container" style={{ marginTop: "-25px" }}>
      <div className="row">
        {dataServices.map((service, i) => (
          <div
            className="col-md-3 feature-box text-center col-sm-4"
            data-aos={"zoom-in"}
            data-aos-delay={`${i}00`}
            data-aos-duration={700}
          >
            <Link to={service.links}>
              <Icofont
                icon={service.icon}
                className={
                  "font-40px light-icon circle-icon fade-icon " +
                  service.classes
                }
              />
            </Link>
            <h4 className="upper-case">{service.title}</h4>
            <p>{service.text}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default WhoWeAreFive;
