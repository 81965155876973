import React from "react";
import ContactForm from "./ContactFormFive";

const ContactThree = () => (
  <section className="contact-us dark-bg">
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <ContactForm />
        </div>
        <div className="col-md-4">
          <h3 className="white-color">Adresse</h3>
          <address>
            Industriestraße 17, 91572 Bechhofen
            <br />
            <i className="icofont icofont-iphone"></i>
            <a href="tel:01575 8944258"> +49 1575 8944258</a>
            <br />
            <i className="icofont icofont-mail"></i>
            <a href="mailto:info@underground-wrap.de">
              &nbsp;info@underground-wrap.de
            </a>
          </address>
          <h3 className="white-color">Arbeitszeiten</h3>
          <p>
            <span>Mo - Do: 16:00 - 19:00</span> <br />
            <span>Fr - Sa: 12:00 - 18:00</span>
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default ContactThree;
