import React from "react";
import Loader from "../../../components/Loader/Loader";
import HeaderOne from "../../../components/Header/HeaderOne";
import PageTitleWidget from "../../../components/PageTitle/PageTitleWidget";
import FooterOne from "../../../components/Footer/FooterOne";
import TabsLackschutz from "../../../components/Tabs/TabsLackschutz";
import PortfolioMasonry from "../../../components/Portfolio/PortfolioMasonry";
import dataPortfolio from "../../../data/Portfolio/lackschutzfolierung.json";
const Lackschutzfolierung = () => (
  <Loader>
    <HeaderOne />
    <PageTitleWidget title="Lackschutzfolierung" />
    <TabsLackschutz />
    <PortfolioMasonry
      columns="four"
      layout="wide"
      type="product"
      space={false}
      classAppend="dark-bg pt-0 pb-0"
      data={dataPortfolio}
    />
    <FooterOne />
  </Loader>
);

export default Lackschutzfolierung;
