import React from "react";
import logoFooter from "../../assets/images/logo-footer.png";
import FooterCopyright from "./FooterCopyright";

const FooterOne = () => (
  <>
    <footer className="footer" id="footer-fixed">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="widget widget-text">
                <div className="logo logo-footer">
                  <a href={`${process.env.PUBLIC_URL}/`}>
                    <img
                      className="logo logo-display"
                      src={logoFooter}
                      alt=""
                    />
                  </a>
                </div>
                <p>
                  Wir sind Underground Wrap. Eine kleine Firma in Ansbach und Kitzingen nahe
                  Ansbach. Wir bieten dir alles rund um das Thema Autofolierung
                  an. Hast auch du einen Wunsch dein Auto neuzugestalten und
                  deiner Kreativität freien Lauf zu lassen? Dann schau bei uns
                  vorbei!
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-2">
              <div className="widget widget-links">
                <h5 className="widget-title">Leistungen</h5>
                <ul>
                  <li>
                    <a href="/lackschutzfolierung">Lackschutzfolierung</a>
                  </li>
                  <li>
                    <a href="/komplettfolierung">Komplettfolierung</a>
                  </li>
                  <li>
                    <a href="/teilfolierung">Teilfolierung</a>
                  </li>
                  <li>
                    <a href="/scheibentoenung">Scheibentönung</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-2">
              <div className="widget widget-links">
                <h5 className="widget-title">Links</h5>
                <ul>
                  <li>
                    <a href="/about">Über Uns</a>
                  </li>
                  <li>
                    <a href="/contact">Kontakt</a>
                  </li>
                  <li>
                    <a href="/leistungen">Leistungen</a>
                  </li>
                  <li>
                    <a href="/impressum">Impressum</a>
                  </li>
                  <li>
                    <a href="/datenschutz">Datenschutz</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="widget widget-text widget-links">
                <h5 className="widget-title">Kontaktiere uns</h5>
                <ul>
                  <li>
                    <i className="icofont icofont-google-map"></i>
                    <a href={process.env.PUBLIC_URL}>
                      Industriestraße 17, 91572 Bechhofen
                    </a>
                  </li>
                  <li>
                    <i className="icofont icofont-iphone"></i>
                    <a href="tel:01575 8944258">+49 1575 8944258</a>
                  </li>
                  <li>
                    <i className="icofont icofont-mail"></i>
                    <a href="mailto:info@underground-wrap.de">
                      info@underground-wrap.de
                    </a>
                  </li>
                  <li>
                    <i className="icofont icofont-globe"></i>
                    <a href={process.env.PUBLIC_URL}>www.underground-wrap.de</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCopyright />
    </footer>
    <div className="footer-height" style={{ height: "463px" }}></div>
  </>
);

export default FooterOne;
