import React from "react";
import Loader from "../../../components/Loader/Loader";
import HeaderOne from "../../../components/Header/HeaderOne";
import PageTitleWidget3 from "../../../components/PageTitle/PageTitleWidget3";
import FooterOne from "../../../components/Footer/FooterOne";
import TabsDefault from "../../../components/Tabs/TabsDefault";
import PortfolioMasonry from "../../../components/Portfolio/PortfolioMasonry";
import dataPortfolio from "../../../data/Portfolio/komplettfolierung.json";
const Komplettfolierung = () => (
  <Loader>
    <HeaderOne />
    <PageTitleWidget3 title="Komplettfolierung" style={{ fontSize: "24px" }} />
    <TabsDefault />
    <PortfolioMasonry
      columns="four"
      layout="wide"
      type="product"
      space={false}
      classAppend="dark-bg pt-0 pb-0"
      data={dataPortfolio}
    />
    <FooterOne />
  </Loader>
);

export default Komplettfolierung;
