import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLax } from "../../helpers/use-lax";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import HeroSliderSection from "../../components/Hero/HeroSliderSection";
import WhoWeAreOne from "../../components/WhoWeAre/WhoWeAreOne";
import CTAFour from "../../components/CTA/CTAFour";
import dataSlider from "../../data/Slider/undergroundwrap-slider-data.json";
import FooterOne from "../../components/Footer/FooterOne";
import Portfolio from "../../components/Portfolio/Portfolio";
import CookieConsent from "react-cookie-consent";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";
import dataTestimonials from "../../data/Testimonials/testimonials-data.json";

//Steuer ID: 203/218/61491
const Home = () => {
  useLax();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <CookieConsent
        location="top"
        buttonText="Ich stimme zu"
        cookieName="Underground Wrap"
        style={{ background: "#353533" }}
        buttonStyle={{
          color: "#fff",
          fontSize: "20px",
          background: "rgba(255,148,38,.86)",
        }}
        expires={150}
      >
        Reden wir über Cookies! Cookies sowie andere Daten und Technologien
        werden von uns und vertrauenswürdigen Partnern genutzt, um Deine
        Erfahrung auf underground-wrap.de und Drittseiten zu analysieren,
        verbessern und personalisieren. Klicke auf „Ich stimme zu“, um
        zuzustimmen und die Services von Underground Wrap weiterhin zu nutzen.
        Mehr erfährst du in unserer
        <a href="/datenschutz" target="_blank">
          &#160;Datenschutzerklärung
        </a>
        .
      </CookieConsent>
      <HeaderOne />
      <HeroSliderSection data={dataSlider} />
      <WhoWeAreOne
        title="Wir sind Undergroundwrap"
        tagline="Ihre Autofolierungsfirma in und um Ansbach und Kitzingen."
        verticalWord="Auto"
        horizontalWord="Folierung."
      >
        Viele Wunschfarben sind bei den meisten Automobil Herstellern nicht
        erhältlich oder kosten unsummen als Aufpreis. <br />
        Hier kommen wir in’s Spiel!
        <br /> Wir die Firma UNDERGROUND WRAP in Ansbach und Kitzingen haben uns auf die
        Folierung von Fahrzeugen aller Art spezialisiert. <br />
        Wir bieten Ihnen eine große Auswahl an unterschiedlichsten Farben,
        Strukturen und Mustern an. Egal ob glänzend, matt, satiniert, metallic
        oder strukturiert. <br />
        Wir arbeiten mit namhaften Herstellern wie Avery, Oracal, Hexis oder
        Teckwrap zusammen. Diese werden von uns kontinuierlich auf Qualität und
        Verarbeitung geprüft.
      </WhoWeAreOne>
      <Portfolio columns="3" layout="wide" items="18" classAppend="pt-0" />
      <TestimonialsOne data={dataTestimonials} />
      <CTAFour
        tagline="Autofolierung in und um Ansbach und Kitzingen."
        title="Underground Wrap"
        textButton="Kontaktiere uns"
        textLink="/contact"
        bg="dark"
      >
        Die günstige Alternative zu einer Neulackierung - Schütze dein Auto,
        Gestalte es wie du möchtest, sei einfach kreativ!
      </CTAFour>
      <FooterOne />
    </Loader>
  );
};

export default Home;
