import React from "react";
import Loader from "../../../components/Loader/Loader";
import HeaderOne from "../../../components/Header/HeaderOne";
import PageTitleWidget5 from "../../../components/PageTitle/PageTitleWidget5";
import FooterOne from "../../../components/Footer/FooterOne";
import TabsScheibentönung from "../../../components/Tabs/TabsScheibentönung";
import PortfolioMasonry from "../../../components/Portfolio/PortfolioMasonry";
import dataPortfolio from "../../../data/Portfolio/scheibentoenung.json";
const Scheibentönung = () => (
  <Loader>
    <HeaderOne />
    <PageTitleWidget5 title="Scheibentönung" />
    <TabsScheibentönung />
    <PortfolioMasonry
      columns="four"
      layout="wide"
      type="product"
      space={false}
      classAppend="dark-bg pt-0 pb-0"
      data={dataPortfolio}
    />
    <FooterOne />
  </Loader>
);

export default Scheibentönung;
