import React from "react";

const TabsDefault = () => (
  <section>
    <div className="container">
      <div className="row">
        <div className="col-sm-8 section-heading">
          <h2
            style={{
              fontSize: "26px",
              marginBottom: "-10px",
              marginTop: "-15px",
            }}
          >
            Komplettfolierung
          </h2>
        </div>
      </div>
      <div className="row mt-50 text-center">
        <p style={{ fontSize: "24px", color: "black" }}>
          Damals war man an die Farben der Hersteller gebunden und tolle sowie
          einzigartige Sonderfarben kosteten ein Vermögen an Aufpreis. Zum Glück
          sind wir im 21. Jahrhundert wo sich einiges geändert hat.
        </p>
        <p style={{ fontSize: "24px", color: "black" }}>
          Heute gibt’s es unzählig viele Farben in den unterschiedlichsten
          Farbnuancen und Intensitäten.
        </p>
        <p style={{ fontSize: "24px", color: "black" }}>
          Es ist uns möglich Ihnen von glänzen, matt, satiniert, strukturiert,
          metallic oder uni einfach alle erdenklichen Farben anzubieten, der
          Vorstellung ist hier fast keine Grenze gesetzt.<br></br> Um Ihnen das
          direkt bei einem unverbindlichen Beratungstermin zeigen zu können
          haben wir Musterfächer von namhaften Herstellern wie: Avery, 3M,
          Hexis, Teckwrap, sowie einige nagelneue Folien wie Inozetec, die mit
          einem einzigartigen Finish punkten.
        </p>
        <p
          style={{ fontSize: "24px", color: "black", fontWeight: "semi-bold" }}
        >
          Bei der Komplettfolierung werden alle lackierten Außenteile beklebt.
          Ebenfalls ist es uns möglich den Fahrzeuglack auch beim Öffnen der
          Türen vollständig verschwinden zu lassen. Hierfür werden die Türen
          sowie Anbauteile von einer KFZ-Fachkraft demontiert sowie anschließend
          wieder zusammengebaut.
        </p>
        <h1 style={{ fontSize: "26px" }}>Entfernen der Folie</h1>
        <p style={{ fontSize: "24px", color: "black" }}>
          Ob die von uns verklebte Folie auch wieder rückstandslos entfernt
          werden kann, ist im Wesentlichen von dem Originalzustand oder der
          Vorbehandlung der Lackarbeiten abhängig. Wenn die Folie als
          Vollfolierung auf dem Lack des Fahrzeugs aufgebracht wurde, welcher
          sich in einwandfreiem Zustand befand, ist eine Rückstandlose
          Entfernung der Folie unproblematisch und auch nach vielen Jahren
          möglich. An nachlackierten Bauteilen stellt sich immer die Frage, ob
          der Untergrund der Belastung des Abziehens standhält.
        </p>
        <p style={{ fontSize: "24px", color: "black" }}>
          Sollte Ihr Fahrzeug in einen Unfall verwickelt sein, muss es nicht
          immer eine Lackierung sein. Wichtig ist, dass das Bauteil grundiert
          ist, um ein Haften der Folie zu gewährleisten. In jedem Fall sollte
          eine Vollfolierung bei dem Kasko Versicherer gemeldet werden, um bei
          einem Schaden den Wert der Folierung ersetzt zu bekommen.
        </p>
      </div>
    </div>
  </section>
);

export default TabsDefault;
