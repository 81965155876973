import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import PageTitleWidget2 from "../../components/PageTitle/PageTitleWidget6";
import FooterOne from "../../components/Footer/FooterOne";

const Privacy = () => (
  <Loader>
    <HeaderOne />
    <PageTitleWidget2 title="Datenschutz" />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 terms">
            <h2 className="font-700">Datenschutz</h2>
            <p>
              Mit dieser Datenschutzerklärung möchten wir Sie über Art, Umfang
              und Zweck der Verarbeitung von personenbezogenen Daten (im
              Folgenden auch nur als "Daten" bezeichnet) aufklären.
              Personenbezogene Daten sind alle Daten, die einen persönlichen
              Bezug zu Ihnen aufweisen, z. B. Name, Adresse, E-Mail-Adresse oder
              Ihr Nutzerverhalten. Die Datenschutzerklärung gilt für alle von
              uns vorgenommene Daten-Verarbeitungsvorgänge sowohl im Rahmen
              unserer Kerntätigkeit als auch für die von uns vorgehaltenen
              Online-Medien.
            </p>
            <p>
              Verantwortlich für die Datenverarbeitung ist:
              <br />
              <br /> Underground Wrap <br />
              Underground Wrap <br />
              Industriestraße 17 <br />
              91572 Bechhofen
              <br />
              Deutschland
              <br />
              https://underground-wrap.de/impressum
            </p>
            <br />
            <h2>Maßgebliche Rechtsgrundlagen</h2>
            <br />
            <p>
              Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
              DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten.
              Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO
              nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder
              Sitzland gelten können. Sollten ferner im Einzelfall speziellere
              Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
              Datenschutzerklärung mit.
            </p>
            <li>
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO) - Die betroffene
              Person hat ihre Einwilligung in die Verarbeitung der sie
              betreffenden personenbezogenen Daten für einen spezifischen Zweck
              oder mehrere bestimmte Zwecke gegeben.
            </li>
            <li>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO) - Die Verarbeitung ist für die Erfüllung eines
              Vertrags, dessen Vertragspartei die betroffene Person ist, oder
              zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf
              Anfrage der betroffenen Person erfolgen.
            </li>
            <li>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO) - Die
              Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
              erforderlich, der der Verantwortliche unterliegt.
            </li>
            <li>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO) - Die
              Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </li>
            <br />
            <p>
              Zusätzlich zu den Datenschutzregelungen der
              Datenschutz-Grundverordnung gelten nationale Regelungen zum
              Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
              zum Schutz vor Missbrauch personenbezogener Daten bei der
              Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
              enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum
              Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung
              besonderer Kategorien personenbezogener Daten, zur Verarbeitung
              für andere Zwecke und zur Übermittlung sowie automatisierten
              Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
              Weiteren regelt es die Datenverarbeitung für Zwecke des
              Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
              auf die Begründung, Durchführung oder Beendigung von
              Beschäftigungsverhältnissen sowie die Einwilligung von
              Beschäftigten. Ferner können Landesdatenschutzgesetze der
              einzelnen Bundesländer zur Anwendung gelangen.
            </p>
            <br />
            <h2>Sicherheitsmaßnahmen</h2>
            <br />
            <p>
              Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
              Berücksichtigung des Stands der Technik, der
              Implementierungskosten und der Art, des Umfangs, der Umstände und
              der Zwecke der Verarbeitung sowie der unterschiedlichen
              Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
              Rechte und Freiheiten natürlicher Personen geeignete technische
              und organisatorische Maßnahmen, um ein dem Risiko angemessenes
              Schutzniveau zu gewährleisten.
            </p>
            <br />
            <p>
              Zu den Maßnahmen gehören insbesondere die Sicherung der
              Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
              Kontrolle des physischen und elektronischen Zugangs zu den Daten
              als auch des sie betreffenden Zugriffs, der Eingabe, der
              Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
              Des Weiteren haben wir Verfahren eingerichtet, die eine
              Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
              Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
              berücksichtigen wir den Schutz personenbezogener Daten bereits bei
              der Entwicklung bzw. Auswahl von Hardware, Software sowie
              Verfahren entsprechend dem Prinzip des Datenschutzes, durch
              Technikgestaltung und durch datenschutzfreundliche
              Voreinstellungen.
            </p>
            <br />
            <h2>Übermittlung personenbezogener Daten</h2>
            <br />
            <p>
              Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt
              es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
              selbstständige Organisationseinheiten oder Personen übermittelt
              oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern
              dieser Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister
              oder Anbieter von Diensten und Inhalten, die in eine Webseite
              eingebunden werden, gehören. In solchen Fall beachten wir die
              gesetzlichen Vorgaben und schließen insbesondere entsprechende
              Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen,
              mit den Empfängern Ihrer Daten ab.
            </p>
            <br />
            <br />
            <h2>Datenverarbeitung in Drittländern</h2>
            <br />
            <p>
              Sofern wir Daten in einem Drittland (d.h., außerhalb der
              Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
              verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme
              von Diensten Dritter oder der Offenlegung bzw. Übermittlung von
              Daten an andere Personen, Stellen oder Unternehmen stattfindet,
              erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
            </p>
            <p>
              Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
              gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir
              die Daten nur in Drittländern mit einem anerkannten
              Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte
              Standardschutzklauseln der EU-Kommission, beim Vorliegen von
              Zertifizierungen oder verbindlicher internen
              Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,
              Informationsseite der EU-Kommission:
              https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).
            </p>
            <br />
            <h2>Löschung von Daten</h2>
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der
              gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung
              erlaubten Einwilligungen widerrufen werden oder sonstige
              Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser
              Daten entfallen ist oder sie für den Zweck nicht erforderlich
              sind). Sofern die Daten nicht gelöscht werden, weil sie für andere
              und gesetzlich zulässige Zwecke erforderlich sind, wird deren
              Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
              gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B.
              für Daten, die aus handels- oder steuerrechtlichen Gründen
              aufbewahrt werden müssen oder deren Speicherung zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              oder zum Schutz der Rechte einer anderen natürlichen oder
              juristischen Person erforderlich ist. Unsere Datenschutzhinweise
              können ferner weitere Angaben zu der Aufbewahrung und Löschung von
              Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig
              gelten.
            </p>
            <p>
              <br />
              <h2>Einsatz von Cookies</h2>
              Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke,
              die Informationen auf Endgeräten speichern und Informationen aus
              den Endgeräten auslesen. Z.B. um den Login-Status in einem
              Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die
              aufgerufenen Inhalte oder verwendete Funktionen eines
              Onlineangebotes speichern. Cookies können ferner zu
              unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
              Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten
              sowie der Erstellung von Analysen der Besucherströme.
            </p>
            <p>
              Hinweise zur Einwilligung: Wir setzen Cookies im Einklang mit den
              gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern
              eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich
              nicht gefordert ist. Eine Einwilligung ist insbesondere nicht
              notwendig, wenn das Speichern und das Auslesen der Informationen,
              also auch von Cookies, unbedingt erforderlich sind, um dem den
              Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst
              (also unser Onlineangebot) zur Verfügung zu stellen. Die
              widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
              kommuniziert und enthält die Informationen zu der jeweiligen
              Cookie-Nutzung.
            </p>
            <p>
              Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: Auf welcher
              datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
              Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon
              ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer
              einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten
              die erklärte Einwilligung. Andernfalls werden die mithilfe von
              Cookies verarbeiteten Daten auf Grundlage unserer berechtigten
              Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
              Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet
              oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen
              Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist,
              um unsere vertraglichen Verpflichtungen zu erfüllen. Zu welchen
              Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
              im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
              Einwilligungs- und Verarbeitungsprozessen auf.
            </p>
            <p>
              Speicherdauer: Im Hinblick auf die Speicherdauer werden die
              folgenden Arten von Cookies unterschieden:
            </p>
            <li>
              Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
              Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
              ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder
              mobile Applikation) geschlossen hat.
            </li>
            <li>
              Permanente Cookies: Permanente Cookies bleiben auch nach dem
              Schließen des Endgerätes gespeichert. So können beispielsweise der
              Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt
              werden, wenn der Nutzer eine Website erneut besucht. Ebenso können
              die mit Hilfe von Cookies erhobenen Daten der Nutzer zur
              Reichweitenmessung verwendet werden. Sofern wir Nutzern keine
              expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen
              (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer
              davon ausgehen, dass Cookies permanent sind und die Speicherdauer
              bis zu zwei Jahre betragen kann.
            </li>
            <p>
              Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): Nutzer
              können die von ihnen abgegebenen Einwilligungen jederzeit
              Widerrufen und zudem einen Widerspruch gegen die Verarbeitung
              entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen
              (weitere Hinweise zum Widerspruch erfolgen im Rahmen dieser
              Datenschutzerklärung). Nutzer können Ihren Widerspruch auch
              mittels der Einstellungen Ihres Browsers erklären. Weitere
              Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
            </p>
            <li>
              Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:
              Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein,
              in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
              Cookies, bzw. der im Rahmen des
              Cookie-Einwilligungs-Management-Verfahrens genannten
              Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
              verwaltet und widerrufen werden können. Hierbei wird die
              Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
              wiederholen zu müssen und die Einwilligung entsprechend der
              gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung
              kann serverseitig und/oder in einem Cookie (sogenanntes
              Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien)
              erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät
              zuordnen zu können. Vorbehaltlich individueller Angaben zu den
              Anbietern von Cookie-Management-Diensten, gelten die folgenden
              Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu
              zwei Jahren betragen. Hierbei wird ein pseudonymer
              Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
              Einwilligung, Angaben zur Reichweite der Einwilligung (z. B.
              welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem
              Browser, System und verwendeten Endgerät gespeichert.
            </li>
            <br />
            <h2>Geschäftliche Leistungen</h2>
            <p>
              Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
              Kunden und Interessenten (zusammenfassend bezeichnet als
              "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
              Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im
              Rahmen der Kommunikation mit den Vertragspartnern (oder
              vorvertraglich), z.B., um Anfragen zu beantworten.
            </p>
            <p>
              Wir verarbeiten diese Daten, um unsere vertraglichen
              Verpflichtungen zu erfüllen. Dazu gehören insbesondere die
              Verpflichtungen zur Erbringung der vereinbarten Leistungen,
              etwaige Aktualisierungspflichten und Abhilfe bei Gewährleistungs-
              und sonstigen Leistungsstörungen. Darüber hinaus verarbeiten wir
              die Daten zur Wahrung unserer Rechte und zum Zwecke der mit diesen
              Pflichten verbundenen Verwaltungsaufgaben sowie der
              Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten
              auf Grundlage unserer berechtigten Interessen an einer
              ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung
              sowie an Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner
              und unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer
              Daten, Geheimnisse, Informationen und Rechte (z.B. zur Beteiligung
              von Telekommunikations-, Transport- und sonstigen Hilfsdiensten
              sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
              Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des
              geltenden Rechts geben wir die Daten von Vertragspartnern nur
              insoweit an Dritte weiter, als dies für die vorgenannten Zwecke
              oder zur Erfüllung gesetzlicher Pflichten erforderlich ist. Über
              weitere Formen der Verarbeitung, z.B. zu Marketingzwecken, werden
              die Vertragspartner im Rahmen dieser Datenschutzerklärung
              informiert.
            </p>
            <p>
              Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen
              wir den Vertragspartnern vor oder im Rahmen der Datenerhebung,
              z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B.
              Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
            </p>
            <p>
              Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs-
              und vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von
              4 Jahren, es sei denn, dass die Daten in einem Kundenkonto
              gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der
              Archivierung aufbewahrt werden müssen (z.B. für Steuerzwecke im
              Regelfall 10 Jahre). Daten, die uns im Rahmen eines Auftrags durch
              den Vertragspartner offengelegt wurden, löschen wir entsprechend
              den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
            </p>
            <p>
              Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
              Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern
              und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise
              der jeweiligen Drittanbieter oder Plattformen.
            </p>
            <p>
              Handwerkliche Leistungen Wir verarbeiten die Daten unserer Kunden
              sowie Auftraggeber (nachfolgend einheitlich als "Kunden"
              bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die
              Beauftragung der gewählten Leistungen oder Werke sowie verbundener
              Tätigkeiten als auch deren Bezahlung und Zustellung bzw.
              Ausführung oder Erbringung zu ermöglichen. Die erforderlichen
              Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw.
              vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die
              zur Auslieferung und Abrechnung benötigten Angaben sowie
              Kontaktinformationen, um etwaige Rücksprachen halten zu können.
            </p>
            <li>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen);
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
              Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie).
            </li>
            <li>
              Betroffene Personen: Interessenten; Geschäfts- und
              Vertragspartner.
            </li>

            <li>
              Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen und
              Kundenservice; Kontaktanfragen und Kommunikation; Büro- und
              Organisationsverfahren; Verwaltung und Beantwortung von Anfragen.
            </li>
            <li>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Rechtliche Verpflichtung (Art.
              6 Abs. 1 S. 1 lit. c. DSGVO); Berechtigte Interessen (Art. 6 Abs.
              1 S. 1 lit. f. DSGVO).
            </li>
            <br />
            <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>
            <p>
              Um unser Onlineangebot sicher und effizient bereitstellen zu
              können, nehmen wir die Leistungen von einem oder mehreren
              Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von
              ihnen verwalteten Servern) das Onlineangebot abgerufen werden
              kann. Zu diesen Zwecken können wir Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
              Datenbankdienste sowie Sicherheitsleistungen und technische
              Wartungsleistungen in Anspruch nehmen.
            </p>
            <p>
              Zu den im Rahmen der Bereitstellung des Hostingangebotes
              verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes
              betreffenden Angaben gehören, die im Rahmen der Nutzung und der
              Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse,
              die notwendig ist, um die Inhalte von Onlineangeboten an Browser
              ausliefern zu können, und alle innerhalb unseres Onlineangebotes
              oder von Webseiten getätigten Eingaben.
            </p>
            <li>
              Verarbeitete Datenarten: Inhaltsdaten (z.B. Eingaben in
              Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes
              und Nutzerfreundlichkeit.
            </li>
            <li>
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
            <p>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </p>
            <li>
              Erhebung von Zugriffsdaten und Logfiles: Wir selbst (bzw. unser
              Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server
              (sogenannte Serverlogfiles). Zu den Serverlogfiles können die
              Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
              Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
              erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
              des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
              Regelfall IP-Adressen und der anfragende Provider gehören. Die
              Serverlogfiles können zum einen zu Zwecken der Sicherheit
              eingesetzt werden, z.B., um eine Überlastung der Server zu
              vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
              sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der
              Server und ihre Stabilität sicherzustellen; Löschung von Daten:
              Logfile-Informationen werden für die Dauer von maximal 30 Tagen
              gespeichert und danach gelöscht oder anonymisiert. Daten, deren
              weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis
              zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
              ausgenommen.
            </li>
            <br />
            <h2>Kontakt- und Anfragenverwaltung</h2>
            <p>
              Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
              Telefon oder via soziale Medien) sowie im Rahmen bestehender
              Nutzer- und Geschäftsbeziehungen werden die Angaben der
              anfragenden Personen verarbeitet soweit dies zur Beantwortung der
              Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich
              ist.
            </p>
            <p>
              Die Beantwortung der Kontaktanfragen sowie die Verwaltung von
              Kontakt- und Anfragedaten im Rahmen von vertraglichen oder
              vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
              vertraglichen Pflichten oder zur Beantwortung von
              (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der
              berechtigten Interessen an der Beantwortung der Anfragen und
              Pflege von Nutzer- bzw. Geschäftsbeziehungen.
            </p>
            <li>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen);
              Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
              Eingaben in Onlineformularen).
            </li>
            <li>Betroffene Personen: Kommunikationspartner.</li>
            <li>Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation.</li>
            <li>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
            <br />
            <h2>Webanalyse, Monitoring und Optimierung</h2>
            <p>
              Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient
              der Auswertung der Besucherströme unseres Onlineangebotes und kann
              Verhalten, Interessen oder demographische Informationen zu den
              Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme
              Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
              erkennen, zu welcher Zeit unser Onlineangebot oder dessen
              Funktionen oder Inhalte am häufigsten genutzt werden oder zur
              Wiederverwendung einladen. Ebenso können wir nachvollziehen,
              welche Bereiche der Optimierung bedürfen.
            </p>
            <p>
              Neben der Webanalyse können wir auch Testverfahren einsetzen, um
              z.B. unterschiedliche Versionen unseres Onlineangebotes oder
              seiner Bestandteile zu testen und optimieren.
            </p>
            <p>
              Sofern nachfolgend nicht anders angegeben, können zu diesen
              Zwecken Profile, d.h. zu einem Nutzungsvorgang zusammengefasste
              Daten angelegt und Informationen in einem Browser, bzw. in einem
              Endgerät gespeichert und aus diesem ausgelesen werden. Zu den
              erhobenen Angaben gehören insbesondere besuchte Webseiten und dort
              genutzte Elemente sowie technische Angaben, wie der verwendete
              Browser, das verwendete Computersystem sowie Angaben zu
              Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten
              uns gegenüber oder gegenüber den Anbietern der von uns
              eingesetzten Dienste einverstanden erklärt haben, können auch
              Standortdaten verarbeitet werden.
            </p>
            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
              nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
              Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die
              im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
              Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
              gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter
              der eingesetzten Software kennen nicht die tatsächliche Identität
              der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in
              deren Profilen gespeicherten Angaben.
            </p>
            <p>
              Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die
              Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
              Ansonsten werden die Daten der Nutzer auf Grundlage unserer
              berechtigten Interessen (d.h. Interesse an effizienten,
              wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
              Informationen zur Verwendung von Cookies in dieser
              Datenschutzerklärung hinweisen.
            </p>
            <li>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile
              mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
            </li>
            <li>
              Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
              IP-Adresse).
            </li>
            <li>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
            <br />
            <h2>Onlinemarketing</h2>
            <p>
              Wir verarbeiten personenbezogene Daten zu Zwecken des
              Onlinemarketings, worunter insbesondere die Vermarktung von
              Werbeflächen oder Darstellung von werbenden und sonstigen Inhalten
              (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller
              Interessen der Nutzer sowie die Messung ihrer Effektivität fallen
              kann.
            </p>
            <p>
              Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
              einer Datei (sogenannte "Cookie") gespeichert oder ähnliche
              Verfahren genutzt, mittels derer die für die Darstellung der
              vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
              werden. Zu diesen Angaben können z.B. betrachtete Inhalte,
              besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
              Kommunikationspartner und technische Angaben, wie der verwendete
              Browser, das verwendete Computersystem sowie Angaben zu
              Nutzungszeiten und genutzten Funktionen gehören. Sofern Nutzer in
              die Erhebung ihrer Standortdaten eingewilligt haben, können auch
              diese verarbeitet werden.
            </p>
            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
              nutzen wir zur Verfügung stehende IP-Masking-Verfahren (d.h.,
              Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der
              Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren
              keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
              gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter
              der Onlinemarketingverfahren kennen nicht die tatsächlich
              Identität der Nutzer, sondern nur die in deren Profilen
              gespeicherten Angaben.
            </p>
            <p>
              Die Angaben in den Profilen werden im Regelfall in den Cookies
              oder mittels ähnlicher Verfahren gespeichert. Diese Cookies können
              später generell auch auf anderen Webseiten die dasselbe
              Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
              Darstellung von Inhalten analysiert als auch mit weiteren Daten
              ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters
              gespeichert werden.
            </p>
            <p>
              Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das
              ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
              Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen und
              das Netzwerk die Profile der Nutzer mit den vorgenannten Angaben
              verbindet. Wir bitten darum, zu beachten, dass Nutzer mit den
              Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen
              der Registrierung, treffen können.
            </p>
            <p>
              Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
              Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können
              wir im Rahmen sogenannter Konversionsmessungen prüfen, welche
              unserer Onlinemarketingverfahren zu einer sogenannten Konversion
              geführt haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die
              Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
              Marketingmaßnahmen verwendet.
            </p>
            <p>
              Solange nicht anders angegeben, bitten wir Sie davon auszugehen,
              dass verwendete Cookies für einen Zeitraum von zwei Jahren
              gespeichert werden.
            </p>
            <p>
              Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die
              Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
              Ansonsten werden die Daten der Nutzer auf Grundlage unserer
              berechtigten Interessen (d.h. Interesse an effizienten,
              wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
              Informationen zur Verwendung von Cookies in dieser
              Datenschutzerklärung hinweisen.
            </p>
            <li>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Marketing; Profile mit nutzerbezogenen
              Informationen (Erstellen von Nutzerprofilen).
            </li>
            <li>
              Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
              IP-Adresse).
            </li>
            <li>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO);
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
            <li>
              Widerspruchsmöglichkeit (Opt-Out): Wir verweisen auf die
              Datenschutzhinweise der jeweiligen Anbieter und die zu den
              Anbietern angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out").
              Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde,
              besteht zum einen die Möglichkeit, dass Sie Cookies in den
              Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch
              Funktionen unseres Onlineangebotes eingeschränkt werden. Wir
              empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten,
              die zusammenfassend auf jeweilige Gebiete gerichtet angeboten
              werden: a Europa: https://www.youronlinechoices.eu. b Kanada:
              https://www.youradchoices.ca/choices. c USA:
              https://www.aboutads.info/choices. d Gebietsübergreifend:
              https://optout.aboutads.info.
            </li>
            <p>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </p>
            <li>
              Google Analytics: Reichweitenmessung und Webanalyse;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://marketingplatform.google.com/intl/de/about/analytics/;
              Datenschutzerklärung: https://policies.google.com/privacy;
              Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für
              die Darstellung von Werbeeinblendungen:
              https://adssettings.google.com/authenticated;
              Auftragsverarbeitungsvertrag:
              https://business.safety.google/adsprocessorterms/; Weitere
              Informationen: Arten der Verarbeitung sowie der verarbeiteten
              Daten: https://privacy.google.com/businesses/adsservices;
              Datenverarbeitungsbedingungen für Google Werbeprodukte und
              Standardvertragsklauseln für Drittlandtransfers von Daten:
              https://business.safety.google/adsprocessorterms.
            </li>
            <br />
            <h2>Präsenzen in sozialen Netzwerken (Social Media)</h2>
            <p>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort
              aktiven Nutzern zu kommunizieren oder um Informationen über uns
              anzubieten.
            </p>
            <p>
              Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
              Raumes der Europäischen Union verarbeitet werden können. Hierdurch
              können sich für die Nutzer Risiken ergeben, weil so z.B. die
              Durchsetzung der Rechte der Nutzer erschwert werden könnte.
            </p>
            <p>
              Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
              Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So
              können z.B. anhand des Nutzungsverhaltens und sich daraus
              ergebender Interessen der Nutzer Nutzungsprofile erstellt werden.
              Die Nutzungsprofile können wiederum verwendet werden, um z.B.
              Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten,
              die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
              Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
              gespeichert, in denen das Nutzungsverhalten und die Interessen der
              Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen
              auch Daten unabhängig der von den Nutzern verwendeten Geräte
              gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der
              jeweiligen Plattformen sind und bei diesen eingeloggt sind).
            </p>
            <p>
              Für eine detaillierte Darstellung der jeweiligen
              Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
              verweisen wir auf die Datenschutzerklärungen und Angaben der
              Betreiber der jeweiligen Netzwerke. Auch im Fall von
              Auskunftsanfragen und der Geltendmachung von Betroffenenrechten
              weisen wir darauf hin, dass diese am effektivsten bei den
              Anbietern geltend gemacht werden können. Nur die Anbieter haben
              jeweils Zugriff auf die Daten der Nutzer und können direkt
              entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
              dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
            </p>
            <li>
              Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
              Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation;
              Feedback (z.B. Sammeln von Feedback via Online-Formular);
              Marketing.
            </li>
            <li>
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
            <p>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </p>
            <li>
              Instagram: Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601
              Willow Road, Menlo Park, CA, 94025, USA; Website:
              https://www.instagram.com; Datenschutzerklärung:
              https://instagram.com/about/legal/privacy.
            </li>
            <li>
              Facebook-Seiten: Profile innerhalb des sozialen Netzwerks Facebook
              - Wir sind gemeinsam mit Facebook Irland Ltd. für die Erhebung
              (jedoch nicht die weitere Verarbeitung) von Daten der Besucher
              unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu diesen
              Daten gehören Informationen zu den Arten von Inhalten, die Nutzer
              sich ansehen oder mit denen sie interagieren, oder die von ihnen
              vorgenommenen Handlungen (siehe unter „Von dir und anderen
              getätigte und bereitgestellte Dinge“ in der
              Facebook-Datenrichtlinie: https://www.facebook.com/policy), sowie
              Informationen über die von den Nutzern genutzten Geräte (z. B.
              IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
              Cookie-Daten; siehe unter „Geräteinformationen“ in der
              Facebook-Datenrichtlinie: https://www.facebook.com/policy). Wie in
              der Facebook-Datenrichtlinie unter „Wie verwenden wir diese
              Informationen?“ erläutert, erhebt und verwendet Facebook
              Informationen auch, um Analysedienste, so genannte
              "Seiten-Insights", für Seitenbetreiber bereitzustellen, damit
              diese Erkenntnisse darüber erhalten, wie Personen mit ihren Seiten
              und mit den mit ihnen verbundenen Inhalten interagieren. Wir haben
              mit Facebook eine spezielle Vereinbarung abgeschlossen
              ("Informationen zu Seiten-Insights",
              https://www.facebook.com/legal/terms/page_controller_addendum), in
              der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
              Facebook beachten muss und in der Facebook sich bereit erklärt hat
              die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B.
              Auskünfte oder Löschungsanfragen direkt an Facebook richten). Die
              Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
              Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde),
              werden durch die Vereinbarungen mit Facebook nicht eingeschränkt.
              Weitere Hinweise finden sich in den "Informationen zu
              Seiten-Insights"
              (https://www.facebook.com/legal/terms/information_about_page_insights_data);
              Dienstanbieter: Facebook Ireland Ltd., 4 Grand Canal Square, Grand
              Canal Harbour, Dublin 2, Irland; Website:
              https://www.facebook.com; Datenschutzerklärung:
              https://www.facebook.com/about/privacy; Standardvertragsklauseln
              (Gewährleistung Datenschutzniveau bei Verarbeitung in
              Drittländern):
              https://www.facebook.com/legal/EU_data_transfer_addendum; Weitere
              Informationen: Vereinbarung gemeinsamer Verantwortlichkeit:
              https://www.facebook.com/legal/terms/information_about_page_insights_data.
            </li>
            <br />
            <h2>Plugins und eingebettete Funktionen sowie Inhalte</h2>
            <p>
              Wir binden in unser Onlineangebot Funktions- und Inhaltselemente
              ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend
              bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich
              zum Beispiel um Grafiken, Videos oder Stadtpläne handeln
              (nachfolgend einheitlich bezeichnet als "Inhalte”).
            </p>
            <p>
              Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
              Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
              IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
              IP-Adresse ist damit für die Darstellung dieser Inhalte oder
              Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
              verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
              Auslieferung der Inhalte verwenden. Drittanbieter können ferner
              sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web
              Beacons" bezeichnet) für statistische oder Marketingzwecke
              verwenden. Durch die "Pixel-Tags" können Informationen, wie der
              Besucherverkehr auf den Seiten dieser Webseite, ausgewertet
              werden. Die pseudonymen Informationen können ferner in Cookies auf
              dem Gerät der Nutzer gespeichert werden und unter anderem
              technische Informationen zum Browser und zum Betriebssystem, zu
              verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur
              Nutzung unseres Onlineangebotes enthalten als auch mit solchen
              Informationen aus anderen Quellen verbunden werden.
            </p>
            <p>
              Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die
              Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
              Ansonsten werden die Daten der Nutzer auf Grundlage unserer
              berechtigten Interessen (d.h. Interesse an effizienten,
              wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
              Informationen zur Verwendung von Cookies in dieser
              Datenschutzerklärung hinweisen.
            </p>
            <li>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes
              und Nutzerfreundlichkeit; Erbringung vertraglicher Leistungen und
              Kundenservice.
            </li>
            <li>
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
            <p>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </p>
            <li>
              Google Fonts: Wir binden die Schriftarten ("Google Fonts") des
              Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken
              der Darstellung der Schriftarten im Browser der Nutzer verwendet
              werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten
              Interessen an einer technisch sicheren, wartungsfreien und
              effizienten Nutzung von Schriftarten, deren einheitlicher
              Darstellung sowie unter Berücksichtigung möglicher
              lizenzrechtlicher Restriktionen für deren Einbindung;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://fonts.google.com/; Datenschutzerklärung:
              https://policies.google.com/privacy.
            </li>
            <li>
              Google Maps: Wir binden die Landkarten des Dienstes “Google Maps”
              des Anbieters Google ein. Zu den verarbeiteten Daten können
              insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die
              jedoch nicht ohne deren Einwilligung (im Regelfall im Rahmen der
              Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://cloud.google.com/maps-platform; Datenschutzerklärung:
              https://policies.google.com/privacy; Widerspruchsmöglichkeit
              (Opt-Out): Opt-Out-Plugin:
              https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für
              die Darstellung von Werbeeinblendungen:
              https://adssettings.google.com/authenticated.
            </li>
            <br />
            <h2>Änderung und Aktualisierung der Datenschutzerklärung</h2>
            <p>
              Wir bitten Sie, sich regelmäßig über den Inhalt unserer
              Datenschutzerklärung zu informieren. Wir passen die
              Datenschutzerklärung an, sobald die Änderungen der von uns
              durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
              informieren Sie, sobald durch die Änderungen eine
              Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
              sonstige individuelle Benachrichtigung erforderlich wird. Sofern
              wir in dieser Datenschutzerklärung Adressen und
              Kontaktinformationen von Unternehmen und Organisationen angeben,
              bitten wir zu beachten, dass die Adressen sich über die Zeit
              ändern können und bitten die Angaben vor Kontaktaufnahme zu
              prüfen.
            </p>
            <br />
            <h2>Rechte der betroffenen Personen</h2>
            <p>
              Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu,
              die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
            </p>
            <li>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </li>
            <li>
              Widerrufsrecht bei Einwilligungen: Sie haben das Recht, erteilte
              Einwilligungen jederzeit zu widerrufen.
            </li>
            <li>
              Auskunftsrecht: Sie haben das Recht, eine Bestätigung darüber zu
              verlangen, ob betreffende Daten verarbeitet werden und auf
              Auskunft über diese Daten sowie auf weitere Informationen und
              Kopie der Daten entsprechend den gesetzlichen Vorgaben.
            </li>
            <li>
              Recht auf Berichtigung: Sie haben entsprechend den gesetzlichen
              Vorgaben das Recht, die Vervollständigung der Sie betreffenden
              Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten
              zu verlangen.
            </li>
            <li>
              Recht auf Löschung und Einschränkung der Verarbeitung: Sie haben
              nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
              dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
              alternativ nach Maßgabe der gesetzlichen Vorgaben eine
              Einschränkung der Verarbeitung der Daten zu verlangen.
            </li>
            <li>
              Recht auf Datenübertragbarkeit: Sie haben das Recht, Sie
              betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
              der gesetzlichen Vorgaben in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten oder deren Übermittlung an
              einen anderen Verantwortlichen zu fordern.
            </li>
            <li>
              Beschwerde bei Aufsichtsbehörde: Sie haben unbeschadet eines
              anderweitigen verwaltungsrechtlichen oder gerichtlichen
              Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
              insbesondere in dem Mitgliedstaat ihres gewöhnlichen
              Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
              mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
              Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
              die Vorgaben der DSGVO verstößt.
            </li>
          </div>
        </div>
      </div>
    </section>
    <FooterOne />
  </Loader>
);

export default Privacy;
