import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import HeaderOne from "../../components/Header/HeaderOne";
import FooterOne from "../../components/Footer/FooterOne";
import PageTitleWidget2 from "../../components/PageTitle/PageTitleWidget2";
import WhoWeAreFive from "../../components/WhoWeAre/WhoWeAreFive";
import CTAOne from "../../components/CTA/CTAOne";
const Leistungen = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne />
      <PageTitleWidget2 title="Leistungen" />
      <WhoWeAreFive />
      <CTAOne
        tagline="Autofolierung in und um Bechhofen."
        title="Underground Wrap"
        textButton="Kontaktiere uns"
        textLink="/contact"
        bg="dark"
      >
        Die günstige Alternative zu einer Neulackierung - Schütze dein Auto,
        Gestalte es wie du möchtest, sei einfach kreativ!
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};
export default Leistungen;
