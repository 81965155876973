import React, { useState, useEffect, useCallback } from "react";
import Icofont from "react-icofont";
import DropdownMenu from "../Navs/DropdownMenu";
import SocialNav from "../Navs/SocialNav";
import MainLogo from "../MainLogo";

const HeaderOne = ({ type }) => {
  const [fixed, setFixed] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const showMenu = () => {
    setCollapse(!collapse);
    const menu = document.getElementById("navbar-menu");
    collapse ? menu.classList.remove("in") : menu.classList.add("in");
  };

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 34) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, []);

  const resizeForm = useCallback(() => {}, []);

  useEffect(() => {
    window.addEventListener("resize", resizeForm);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", resizeForm);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [resizeForm, handleScroll]);

  return (
    <>
      <nav
        className={
          "navbar navbar-expand-lg navbar-light navbar-fixed dark bootsnav on no-full " +
          (fixed || type === "dark" ? "" : "navbar-transparent")
        }
      >
        <div className="container">
          <button
            type="button"
            className={"navbar-toggler " + (collapse ? "collapsed" : "")}
            data-toggle="dropdown"
            data-target="#navbar-menu"
            onClick={showMenu}
          >
            <Icofont icon="navigation-menu" />
          </button>
          <MainLogo showMenu={showMenu} />
          <DropdownMenu />
          <SocialNav></SocialNav>
        </div>
      </nav>
    </>
  );
};

export default HeaderOne;
